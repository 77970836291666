<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>


<style>
#app {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #f3f3f9;
}
.v-application,
.v-application .text-h5,
.v-application .text-h6 {
  font-family: 'Poppins', sans-serif !important;
}
</style>