<template>
  <div>
    <SideBar
      v-if="isLoggedIn"
      :navigationBarTitle="applicationName"
      :userGroup="userGroup"
       @no_of_applications="no_of_applications"
    ></SideBar>
    <v-main>
       <div v-if="emptyScreenShow">
          <EmptyScreenApplication></EmptyScreenApplication>
      </div>
      <div v-else>
       <WelcomePage></WelcomePage>
      </div>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue"; 
import EmptyScreenApplication from "../components/EmptyScreenApplication.vue";
import WelcomePage from "../components/WelcomePage.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface";
export default {
  name: "Home",
  components: {
    SideBar,
    EmptyScreenApplication, 
    WelcomePage
  },
  data() {
    return {
      applicationName: "",
      applicationList: [],
      userGroup: "",
      isLoggedIn: false,
      emptyScreenShow : false, 
    }
  },
  methods:{
     no_of_applications(count){
      this.applicationsCount = count;
      if( this.applicationsCount != 0){
        this.emptyScreenShow = false; 
      }
      else{
        this.emptyScreenShow = true;
      }
    },
  },
  mounted() {
    if ((!getFromLocalStorage("token") || !getFromLocalStorage("authType"))) {
      this.$router.push({
        path: "/login",
      });
    }
    else{
      this.isLoggedIn = true;
    }
  },
};
</script>