import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookie from "vue-cookie";
import "@/assets/styles/main.css";
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import VuePhoneNumberInput from 'vue-phone-number-input';
import DatetimePicker from 'vuetify-datetime-picker';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import i18n from './i18n'
import store from './store'
import JsonCSV from "vue-json-csv";
import moment from 'moment'
import VueExcelEditor from 'vue-excel-editor'
import VueNativeSock from 'vue-native-websocket'

Vue.component('downloadCSV',JsonCSV);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VueCookie);
Vue.use(DatetimePicker);
Vue.use(VueExcelEditor);
Vue.use(VueNativeSock, `${process.env.VUE_APP_WS_URL}`, { 
  connectManually: true,
})

Vue.prototype.moment = moment

Vue.config.productionTip = false

Vue.filter('fromNow', function (value) {
  if(!Number.isNaN(Number(value))){
    return moment(value).fromNow()
  }
  return value
});

Vue.filter('ellipsis', function (value) {
  if(value.length>10){
    return `${value.slice(0, 7)}…`
  }
  return value
});

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
