<template>
  <div>
      <div v-if="notificationTypeObj && notificationTypeObj['notifications'] && notificationTypeObj['notifications'].length > 0">
      <v-card-text class="pa-0">
        <div style="height: 360px; overflow-y: scroll;" class="notificationsList" ref="notificationsList" @scroll="handleScroll">
          <v-list three-line>
              <template v-for="(notification, index) in notificationTypeObj['notifications']">
                <v-divider v-if="index !== 0" :key="`${notification.id}-${index}`"></v-divider>
                <v-list-item :key="`list-item-${notification.id}-${index}`">
                  <v-list-item-content>
                    <v-list-item-title style="color:blue" v-text="`${notification.title} - ${notification.appName}`"></v-list-item-title>
                    <v-list-item-subtitle v-if="notification.type === 'HTML'" v-html="notification.data"></v-list-item-subtitle>
                    <v-list-item-subtitle v-else v-text="notification.data"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      <v-icon class="mr-2" size="18px">mdi-clock</v-icon>
                      {{notification.timestamp | fromNow}}
                    </v-list-item-action-text>
                    <v-btn v-if="activeTabLowerCase == 'unread' || activeTabLowerCase == 'breach'" title="mark as read" icon @click="markAsRead(index, notification.id, activeTabLowerCase)">
                      <v-hover v-slot="{hover}">
                        <v-icon :color= "hover? 'blue': 'black'">mdi-close</v-icon>
                      </v-hover>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
          </v-list>
        </div>
      </v-card-text>
      </div>
      <div v-else  class="emptyScreen">
        <div class="innerEmptyScreen">
            <div>
                <p style="font-weight:500; color:blue; ">No Notifications to Display</p>
            </div>
        </div>
      </div>
      <div v-if="loadMoreSpinner">
        <SpinnerComponent></SpinnerComponent>
      </div>
      <div v-if="showMessageDialog">
        <MessageDialog
          :displayDialog="showMessageDialog"
          :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
          @closeMessageDialog="closeMessageDialog"
        ></MessageDialog>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface";
export default {
  props: ["notificationTypeObj", "newNotificationsCount", "activeTabLowerCase"],
  data() {
    return {
      loadMoreSpinner:false,
      currentTab:"",
      showMessageDialog:false
    };
  },
  components: {
    MessageDialog,
    SpinnerComponent,
  },
  methods: {
    markAsRead(index, notificationId, type) {
      this.$emit("markAsRead", index, notificationId, type)
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    async handleScroll(event) {
      let container = event.target;
      let scrollHeight = container.scrollHeight;
      let scrollTop = container.scrollTop;
      let clientHeight = container.clientHeight;
      let bottomOffset = scrollHeight - (scrollTop + clientHeight);
      if (bottomOffset <= 10 && this.notificationTypeObj.lastEvaluatedKey) {
          this.loadMoreSpinner = true;
          axios({
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/viewUserNotifications",
              data: {
                userId: getFromLocalStorage('userId'),
                token:  getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
                lastEvaluatedKey: this.notificationTypeObj.lastEvaluatedKey,
              },
              headers: {
                "content-type": "application/json",
              },
          })
          .then((apiResponse) => {
              let moreNotificationsData = apiResponse.data.userNotificationsData;
              this.notificationTypeObj.notifications.push(...moreNotificationsData.notifications);
              this.notificationTypeObj.lastEvaluatedKey = moreNotificationsData.lastEvaluatedKey;
              this.loadMoreSpinner = false;
          })
          .catch((apiError) => {
            this.loadMoreSpinner = false;
            if (
              apiError.response &&
              apiError.response.status &&
              apiError.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                apiError.response &&
                apiError.response.data &&
                apiError.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code:
                    apiError.response.data.errorObject.error_code,
                  error_uuid:
                    apiError.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      }
    },
  },
  mounted() {
    this.currentTab = this.$props.activeTabLowerCase;
  }
};
</script>

<style scoped>
  .emptyScreen{
    height:350px;
    background-color: #ffffff;
    text-align: center;
    padding:15%;
  }
  .innerEmptyScreen{
    margin:30px;
  }
  .light_blue {
    background: rgb(226, 232, 255) !important;
  }
  /* width */
  .notificationsList::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .notificationsList::-webkit-scrollbar-track { 
    border-radius: 8px;
  }
  
  /* Handle */
  .notificationsList::-webkit-scrollbar-thumb {
    background:rgb(42, 64, 154);
    border-radius: 8px;
  }

  /* Handle on hover */
  .notificationsList::-webkit-scrollbar-thumb:hover {
    background:rgb(42, 64, 154);
  }
</style>
