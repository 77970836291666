import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "UserLayout" */ '/App.vue'),
    component: Home 
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginPage.vue'),
    // props: true
  },
  {
    path: '/applicationDetails',
    name: 'ApplicationDetails',
    component: () => import(/* webpackChunkName: "ApplicationDetails" */ '../views/ApplicationDetails.vue'),
  },
  {
    path: '/ExternalEmbedAddRecord',
    name: 'ExternalEmbedAddRecord',
    component: () => import(/* webpackChunkName: "IframeAddRecordDialogComponent" */ '../components/ExternalEmbedValidation.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
