<template>
    <v-card width="800px" height="500px">
      <v-card-title class="ml-2" style="color:rgb(42, 64, 154);">
        Notifications
        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="$emit('closeNotifications')"><v-icon>mdi-close-circle</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs
        v-model="tab"
        bg-color="primary"
      >
        <v-tab
          v-for="item in tabNames"
          :key="item"
        >
          {{ item }}
          <v-badge 
            v-if="item.toLowerCase()!=='read'"
            :value="item.toLowerCase()==='unread' ? unreadCount : breachCount"
            :content="item.toLowerCase()==='unread' ? unreadCount : breachCount" 
            color="#FF6B00"
            inline
          ></v-badge>
        </v-tab>
        <v-spacer></v-spacer>
        <div>
          <v-hover v-slot="{hover}">
            <div>
              <v-btn
              text
              v-if="activeTabLowerCase =='unread' && notificationsUnRead.notifications && notificationsUnRead.notifications.length>0" title="mark all as read"
              @click="markAllAsRead(notificationsUnRead.notifications, 'unread')"
              style="color: #0e0d0d; margin: 10px;"
              :color="hover? 'blue': 'black'"
            >
              Mark All as Read
            </v-btn>
            </div>
          </v-hover>
        </div>
        <div>
          <v-hover v-slot="{hover}">
            <div>
              <v-btn
                text
                v-if="activeTabLowerCase =='breach' && notificationsBreach.notifications && notificationsBreach.notifications.length>0"
                title="mark all as read"
                @click="markAllAsRead(notificationsBreach.notifications, 'breach')"
                style="color: #0c0808; margin: 10px;"
                :color="hover? 'blue': 'black'"
              >
                Mark All as Read
              </v-btn>
          </div>
          </v-hover>
        </div>
      </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(notificationTypeObj, index) in [notificationsRead, notificationsUnRead, notificationsBreach]"
              :key="index"
            >
            <NotificationComponent
              :notificationTypeObj="notificationTypeObj"
              :activeTabLowerCase ="activeTabLowerCase"
              @markAsRead="markAsRead"
              @markAllAsRead="markAllAsRead"
              @closeDialog="closeDialog"
            >
            </NotificationComponent>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </template>
  
<script>
import NotificationComponent from "./NotificationComponent.vue";
export default {
  props: ["notificationsRead", "notificationsUnRead", "notificationsBreach"],
  data() {
    return {
      tab: null,
      tabNames: [ 'Read', 'UnRead', 'Breach'],
      loadMoreSpinner:false
    };
  },
  components: {
    NotificationComponent
  },
  computed:{
    unreadCount() {
      const count = this.notificationsUnRead.notifications.length;
      if (this.notificationsUnRead.lastEvaluatedKey !== '') {
        return `${count}+`;
      } else {
        return count;
      }
    },
    breachCount() {
      const count = this.notificationsBreach.notifications.length;
      if (this.notificationsBreach.lastEvaluatedKey !== '') {
        return `${count}+`;
      } else {
        return count;
      }
    },
    activeTabLowerCase() {
      let tabName = this.tabNames[0]
      if (this.tab) {
        tabName = this.tabNames[this.tab];
      }
      return tabName.toLowerCase()
    }
  },
  methods: {
    markAsRead(index, notificationId, type) {
      this.$emit('markAsRead', index, notificationId, type)
    },
    markAllAsRead(notifications, type) {
      let notificationIds = notifications.map((notification) => notification.id)
      this.$emit('markAllAsRead', notificationIds, type);
    },
    closeDialog(closeDialogFlag) {
      this.$emit('closeDialog', closeDialogFlag);
    }
  }
}
</script>