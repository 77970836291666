<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      max-width="88%"
      style="display: flex; flex-direction: column"
    >
      <div style="background-color: #ffffff">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 104px;
            border-bottom: 1px solid #d5dade;
            padding: 32px;
          "
        >
          <div max-width="100%" style="display: flex; align-items: center">
            <v-img src="@/assets/resourcesFolder.svg"></v-img>
            <span
              style="
                margin-left: 10px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              "
              >Resources</span
            >
          </div>
          <v-icon style="color: blue; cursor: pointer" @click="clickClose()"
            >mdi-close</v-icon
          >
        </div>
        <div
          style="display: flex; flex-direction: column; padding: 3.5%"
          v-if="
            filteredResourcesObject &&
            filteredResourcesObject.resources &&
            filteredResourcesObject.resources.length > 0
          "
        >
          <span
            style="
              font-family: 'Poppins';
              padding-left: 20px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              text-align: left;
              text-transform: uppercase;
              color: #7c8287;
            "
            >Uploaded Resources</span
          >
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              flex-wrap: wrap;
            "
          >
            <v-card
              v-for="item in filteredResourcesObject.resources"
              :key="item.file"
              @click="downloadFile(item)"
              elevation="0"
              style="
                width: 18%;
                background: #f5f7fb;
                border-radius: 8px;
                height: 15rem;
                margin: 1%;
              "
            >
              <v-card-actions
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: row">
                  <div style="width: 30px; height: 30px">
                    <v-img
                      v-if="item.file.includes('.pdf')"
                      src="@/assets/pdf.svg"
                      style="width: 100%"
                    ></v-img>
                    <v-img
                      v-else-if="item.file.includes('.docx')"
                      src="@/assets/doc.svg"
                    ></v-img>
                    <v-img
                      v-else-if="item.file.includes('.xls')"
                      src="@/assets/xls.svg"
                    ></v-img>
                    <v-img v-else key="item" src="@/assets/image.svg"></v-img>
                  </div>
                  &nbsp;
                  <span
                    style="
                      margin-top: 5px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 8ch;
                    "
                    >{{ item.file.split(".")[0] }}</span
                  >
                </div>
                <v-img
                  src="@/assets/download.svg"
                  max-width="15%"
                  max-height="80%"
                ></v-img>
              </v-card-actions>
              <v-card-actions style="padding: 16px;">
                <div v-if="item.isThumbnail"
                  style="
                    width: 232.8px;
                    height: 154px;
                  "
                ><img 
                    v-if="item.isThumbnail"
                    :src="item.thumbnailUrl"
                    style="widht:100%; height:100%;"
                    :alt="item.file"
                  ></img>
                </div>
                <div v-else
                  style="
                    width: 232.8px;
                    height: 154px;
                    background-color: #e8ecf4;
                  "
                >
                  <v-img
                    v-if="item.file.includes('.pdf')"
                    src="@/assets/DarkPdf.svg"
                    max-width="20%"
                    style="left: 36%; top: 30%; justify-content: center"
                  ></v-img>
                  <v-img
                    v-else-if="item.file.includes('.docx')"
                    src="@/assets/Darkdoc.svg"
                    max-width="28%"
                    style="left: 36%; top: 30%; justify-content: center"
                  ></v-img>
                  <v-img
                    v-else-if="item.file.includes('.xls')"
                    src="@/assets/Darkxls.svg"
                    max-width="28%"
                    style="left: 36%; top: 30%; justify-content: center"
                  ></v-img>
                  <v-img
                    v-else
                    src="@/assets/Darkimage.svg"
                    max-width="28%"
                    style="left: 36%; top: 30%; justify-content: center"
                  ></v-img>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <div
          v-else
          style="
            padding: 32px;
            height: 104px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
            text-transform: uppercase;
            color: #7c8287;
          "
        >
          <span>No Resources Data</span>
        </div>
      </div>
    </v-dialog>
		<div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage} from "../store/localStorageInterface"

export default {
  props: ["resourcesDialog", "resourcesObject"],
  components: {
    MessageDialog
  },
  data() {
    return {
      dialog: false,
      filteredResourcesObject: {},
			showMessageDialog: false,
			errorMessageObject: {},
			errorMessageShow: false
		};
  },
  methods: {
    clickClose(){
      this.dialog = false;
      this.$emit("closeResourcesDialog", {
        resourcesDialog: false
      });
    },
    downloadFile(fileLink) {
      var file_name = this.$props.resourcesObject.applicationId + "/KnowledgeBaseResources/" + fileLink.file;
      var read_body = {
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        applicationId: this.$props.resourcesObject.applicationId,
        userId: this.$route.query.userId,
        file_name: file_name,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
          read_body
        )
        .then((readSuccess) => {
          window.open(readSuccess.data.data, "_blank");
        })
        .catch((readError) => {
          if (
            readError.response &&
            readError.response.status &&
            readError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              readError.response &&
              readError.response.data &&
              readError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: readError.response.data.errorObject.error_code,
                error_uuid: readError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
  },
  async mounted(){
    this.dialog = this.$props.resourcesDialog;
    this.filteredResourcesObject = this.$props.resourcesObject;
  }
};
</script>
